import React, { Component } from "react";

import Slider from "../SliderView/MainSlider";

import { Link } from "react-router-dom";
import { t } from "react-multi-lang";

const $ = window.$;

class SearchComponent extends Component {

  state = {
    maindata: null,
    errorHandle: 0,
    loading: true,
  };
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.setState({ loading: false, maindata: this.props.location.state.suggestions });
  }

  renderVideoList = (maindata) => {
    return (
      <React.Fragment>
        <div className="main-slidersec">

          <h3 className="">
            Search Results
            <i className="fas fa-angle-right ml-2" />
          </h3>
          <Slider>
            {maindata.map(movie =>
              <Slider.Item
                movie={movie}
                key={movie.admin_video_id}
              >
                item1
              </Slider.Item>
            )}
          </Slider>
        </div>
      </React.Fragment>
    );
  };

  renderVideoNoData = (index) => {
    return (
      <React.Fragment key={index}>
        {/*<div className="main-slidersec">
          <h3 className="">
            {maindata.title}
            <i className="fas fa-angle-right ml-2" />
          </h3>

          <div className="no-data">
            No Videos Found!!
          </div>
        </div>*/}
      </React.Fragment>
    );
  };

  render() {
    const {
      loading,
      maindata,
    } = this.state;
    return (
      <div>

        <div className="main p-40">
          {/* {renderMyList} */}

          {loading
            ? ""
            : maindata.length > 0 ?
              this.renderVideoList(maindata)
              : this.renderVideoNoData(0)}


          <div className="height-100" />
        </div>
      </div>
    );
  }
}

export default SearchComponent;
